<template>
    <v-container fluid>
        <v-card height="100%">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" />
            <v-divider class="mb-2 mt-2"></v-divider>
            <!-- <pie_disponibilidede_resumida :apontData="apontData"/>
            <tabela_disponibilidade_resumida :apontData="apontData" /> -->

            <pie_disponibilidede_resumida :dispData="dispData"/>
            <tabela_disponibilidade_resumida :dispData="dispData" />
        </v-card>
    </v-container>
</template>

<script>
import tabela_disponibilidade_resumida from "../charts/tabela_disponibilidade_resumida.vue";
import pie_disponibilidede_resumida from "./pie_disponibilidede_resumida.vue";
import toolbarCharts from '../charts/toolbar/index.vue'
import { useAppCharts } from "../../../stores/appCharts";
import { decimalToTime }  from "../../utils/funcoes";




export default {
    name: "disponibilidade_resumida",
    components: {
        tabela_disponibilidade_resumida,
        toolbarCharts,
        pie_disponibilidede_resumida,
    },
    computed: {
        appCharts() {
            return useAppCharts()
        }
    },
    data() {
        return {
            // apontData: null
            dispData: null
        }
    },
    mounted() {
        // this.getDataApont()
        this.getDataDisp()
        // this.appCharts().disp_resumida()
    },
    methods: {
        toolbarFiltros(pFiltro, pValue) {
            switch (pFiltro) {
                case 'machine':
                    console.log('maquinas: ', pValue)
                    break;
                case 'shift':
                    console.log('turno: ', pValue)
                    break;
                case 'group':
                    console.log('grupo: ', pValue)
                    break;
                case 'startDate':
                    console.log('início: ', pValue)
                    break
                case 'endDate':
                    console.log('fim: ', pValue)
                    break
            };
        },
        getDataDisp() {
            this.appCharts.disp_resumida().then(() => {
                this.$nextTick(() => {
                    this.dispData = this.appCharts().resourceDataDisp
                })
                console.log('data do back: ', this.dispData)
            })
        }
        // getDataApont() {
        //     this.appCharts.listar_apontamentos_chart().then(() => {
        //         this.$nextTick(() => {
        //             this.apontData = this.appCharts.resourceApont.map(Apont => ({
        //                 NameApont: Apont.tipo == 'Produção' ? Apont.tipo : Apont.desc_motivo_parada,
        //                 TotalApont: Apont.total_hr,
        //                 TotalHrApont: decimalToTime(Apont.total_hr),
        //                 TotalHrDisp: decimalToTime(Apont.total_hr_disp),
        //                 TotalHrProd: decimalToTime(Apont.total_hr_prod),
        //                 indiceDisp: Apont.indiceDisp + '%'
        //             }))
        //         })
        //     }).catch(error => {
        //         console.error("Erro carregar MPS", error)
        //     })
           
        // }
    }
};
</script>