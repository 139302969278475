import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_toolbarCharts = _resolveComponent("toolbarCharts")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_pie_disponibilidede_resumida = _resolveComponent("pie_disponibilidede_resumida")
  const _component_tabela_disponibilidade_resumida = _resolveComponent("tabela_disponibilidade_resumida")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { height: "100%" }, {
        default: _withCtx(() => [
          _createVNode(_component_toolbarCharts, { onToolbarFiltros: $options.toolbarFiltros }, null, 8 /* PROPS */, ["onToolbarFiltros"]),
          _createVNode(_component_v_divider, { class: "mb-2 mt-2" }),
          _createCommentVNode(" <pie_disponibilidede_resumida :apontData=\"apontData\"/>\n            <tabela_disponibilidade_resumida :apontData=\"apontData\" /> "),
          _createVNode(_component_pie_disponibilidede_resumida, { dispData: $data.dispData }, null, 8 /* PROPS */, ["dispData"]),
          _createVNode(_component_tabela_disponibilidade_resumida, { dispData: $data.dispData }, null, 8 /* PROPS */, ["dispData"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}