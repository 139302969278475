<template>
    <motivo_de_parada />
</template>

<script>
import motivo_de_parada from '../../producao/charts/motivo_de_parada.vue';
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
    "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
);
export default {
    name: "paretoParadas",
    components: {
        motivo_de_parada
    },
    mounted() {
        $(`[style="position: fixed;
                top: 10px;
                left: 10px;
                right: 10px;
                font-size: 14px;
                background: #EEF2FF;
                color: #222222;
                z-index: 999999999;
                text-align: left;
                border: 1px solid #EEEEEE;
                padding: 10px 11px 10px 50px;
                border-radius: 8px;
                font-family: Helvetica Neue, Helvetica, Arial;"]`).remove()
        // import { registerLicense } from "@syncfusion/ej2-base";
        // registerLicense(
        //     "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
        // );
        // $('')
    }
}
</script>

<style scoped></style>