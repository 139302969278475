<template>
    <v-container fluid>
        <div class="control-section">
            <div align="center">
                <v-card style="background-color: white; height: 94vh;">
                    <ejs-chart style="display: block" :theme="theme" align="center" id="chartcontainer" :title="title"
                        :primaryXAxis="primaryXAxis" :primaryYAxis="primaryYAxis" :chartArea="chartArea" :width="width"
                        :legendSettings="legendSettings" :tooltip="tooltip">
                        <e-series-collection>
                            <e-series :dataSource="seriesData" type="Pareto" xName="x" yName="y"
                                name="QUANTIDADE DE HORAS" width="2" columnWidth="0.4" :paretoOptions="paretoOptions"
                                :cornerRadius="cornerRadius" :fill="'#121935'">
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </v-card>
            </div>
        </div>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { Browser } from "@syncfusion/ej2-base";
import {
    ChartComponent,
    SeriesDirective,
    SeriesCollectionDirective,
    LineSeries,
    StackingColumnSeries,
    Tooltip,
    ColumnSeries,
    Category,
    Legend,
    ParetoSeries,
    Highlight,
} from "@syncfusion/ej2-vue-charts";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
    .replace(/-dark/i, "Dark")
    .replace(/contrast/i, "Contrast")
    .replace(/-highContrast/i, "HighContrast");

export default {
    name: "pareto_motivo_de_parada",
    components: {
        "ejs-chart": ChartComponent,
        "e-series-collection": SeriesCollectionDirective,
        "e-series": SeriesDirective,
    },
    data: () => {
        return {
            dialog: false,
            theme: theme,
            seriesData: [
                { x: "MOTIVO DE PARADA NÃO INFORMADO", y: 370 },
                { x: "FALTA DE MATERIAL", y: 330 },
                { x: "QUEBRA DE MAQUINA", y: 280 },
                { x: "DIMENSIONAL", y: 270 },
                { x: "FALTA DE SERVIÇO/PROJETO", y: 270 },
                { x: "FALTA DE PROJETO", y: 260 },
                { x: "LIMPEZA", y: 260 },
                { x: "DÚVIDA DE PROJETO", y: 240 },
                { x: "REUNIÃO", y: 230 },
                { x: "MICRO PARADAS", y: 5 },
            ],

            //Initializing Primary X Axis
            primaryXAxis: {
                interval: 1,
                valueType: "Category",
                majorGridLines: { width: 0 },
                minorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 },
                lineStyle: { width: 0 },
                labelIntersectAction: Browser.isDevice ? "None" : "Rotate45",
                labelRotation: Browser.isDevice ? -45 : 0,
            },

            //Initializing Primary Y Axis
            primaryYAxis: {
                title: "Soma do Tempo do Apontamento",
                minimum: 0.0,
                maximum: 450.0,
                interval: 50.0,
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 },
                majorGridLines: { width: 1 },
                minorGridLines: { width: 1 },
                minorTickLines: { width: 0 },
                labelFormat: "{value}.0000",
            },
            chartArea: {
                border: {
                    width: 0,
                },
            },
            paretoOptions: {
                marker: { visible: true, isFilled: true, width: 7, height: 7 },
                dashArray: "3,2",
                width: 2,
            },
            cornerRadius: {
                topLeft: Browser.isDevice ? 4 : 6,
                topRight: Browser.isDevice ? 4 : 6,
            },
            width: Browser.isDevice ? "100%" : "75%",
            legendSettings: {
                visible: true,
                enableHighlight: true,
            },
            tooltip: {
                enable: true,
                shared: true,
                format: "${series.name} : <b>${point.y}</b>",
            },
            title: "Motivo de Parada / Grupo da máquina / Máquina:",
        };
    },
    provide: {
        chart: [
            StackingColumnSeries,
            LineSeries,
            Category,
            ColumnSeries,
            Legend,
            Tooltip,
            ParetoSeries,
            Highlight,
        ],
    },
    methods: {
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        formattedName() {
            return this.formatTeste();
        }
    },
    mounted(){
        useAppCharts().relatorio_de_motivos_de_parada()
    }
};
</script>