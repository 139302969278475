<template>
    <disponibilidade_resumida />
</template>

<script>
import disponibilidade_resumida from '../../producao/charts/disponibilidade_resumida.vue';
export default {
    name: "dispResumida",
    components: {
        disponibilidade_resumida
    }
}
</script>

<style scoped></style>