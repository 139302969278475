import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_navbar = _resolveComponent("navbar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_v_main = _resolveComponent("v-main")
  const _component_v_app = _resolveComponent("v-app")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app, { style: {"background-color":"#ECEFF1"} }, {
      default: _withCtx(() => [
        _createVNode(_component_navbar),
        _createVNode(_component_v_main, { style: {"background-color":"#E0E0E0"} }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}