<template>
    <Desempenho_da_empresa />
</template>

<script>
import Desempenho_da_empresa from '../../producao/charts/desempenho_da_empresa.vue';
export default {
    name: "desempenho",
    components: {
        Desempenho_da_empresa
    }
}
</script>

<style scoped></style>