<template>
    <v-container fluid height="100vh">
        <v-card height="100%" width="100%">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" />
            <multiple_charts_desempenho_da_empresa/>
        </v-card>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import multiple_charts_desempenho_da_empresa from '../charts/multiple_charts.desempenho_da_empresa.vue'
import toolbarCharts from '../charts/toolbar/index.vue'

export default {
    name: 'desempenho_da_empresa',
    components: {
        multiple_charts_desempenho_da_empresa,
        toolbarCharts
    },
    data() {
        return {
        };
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },
    watch: {

    },
    methods: {
        toolbarFiltros(pFiltro, pValue) {
            switch (pFiltro) {
                case 'machine':
                    console.log('maquinas: ', pValue)
                    break;
                case 'shift':
                    console.log('turno: ', pValue)
                    break;
                case 'group':
                    console.log('grupo: ', pValue)
                    break;
            };
        }
    },
};
</script>
