<template>
    <div class="pie  ml-2 mr-2 " style="background-color: #dce0e6;">
        <v-row justify="center">
            <h1 class="mt-10" style="font-size: 24px; text-align: left">MONITORAMENTO DE PRODUÇÃO</h1>
        </v-row>
        <v-row class="d-flex flex-row flex-wrap mt-2 ml-4 mb-4 mr-4" justify="center">
            <div class="control-selection">
                <div aligh="center" id="texto">
                    <ejs-accumulationchart ref="pie" :theme="theme" style="display: block; width: 800px; height: 500px"
                        aligh="center" id="chartcontainer" :title="title" :legendSettings="legendSettings"
                        :tooltip="tooltip" enableSmartLables="true" :enableAnimation="enableAnimation"
                        :enableBorderOnMouseMove="false">
                        <e-accumulation-series-collection>
                            <e-accumulation-series :dataSource="apontData" :startAngle="startAngle"
                                :explodeOffset="explodeOffset" :dataLabel="dataLabel" :explodeIndex="explodeIndex"
                                :radius="radius" xName="NameApont" yName="TotalApont" name="Browser" innerRadius="0%"
                                explode="true">
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </v-row>
    </div>
</template>

<script>
import { Browser } from "@syncfusion/ej2-base";
import {
    AccumulationChartComponent,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    AccumulationTooltip,
    PieSeries,
    AccumulationDataLabel,
    AccumulationLegend,
} from "@syncfusion/ej2-vue-charts";
import { useAppCharts } from "../../../stores/appCharts";
// import { ObjectType } from "@syncfusion/ej2/pdf-export";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
    .replace(/-dark/i, "Dark")
    .replace(/contrast/i, "Contrast")
    .replace(/-highContrast/i, "HighContrast");

export default {
    name: "pie_disponibilidade_resumida",
    components: {
        "ejs-accumulationchart": AccumulationChartComponent,
        "e-accumulation-series-collection": AccumulationSeriesCollectionDirective,
        "e-accumulation-series": AccumulationSeriesDirective,
    },
    props: {
        // apontData: Object,
        dispData: Object
    },
    data: function () {
        return {
            count: 0,
            legendSettings: {
                ///Tamanho do texto lateral
                padding: 10,
                width: "300px",
                visible: true,
                textStyle: {
                    size: "20px",
                    fontWeight: "Bold",
                    color: "#000000",
                },
            },
            theme: theme,
            seriesData: null,
            // seriesData: Browser.isDevice
            //     ? [
            //         {
            //             Browser: "Produzindo",
            //             Porcentagem: 59.28,
            //             DataLabelMappingName: "Produzindo: 59.28%",
            //         },
            //         {
            //             Browser: "Manutenção",
            //             Porcentagem: 2.48,
            //             DataLabelMappingName: Browser.isDevice
            //                 ? "Manutenção <br> 2.48%"
            //                 : "Manutenão: 2.48%",
            //         },
            //         {
            //             Browser: "Falta de Material",
            //             Porcentagem: 5.55,
            //             DataLabelMappingName: "Falta de Material: 5.55%",
            //         },
            //         {
            //             Browser: "Não Informado",
            //             Porcentagem: 22.8,
            //             DataLabelMappingName: "Não informado: 22.80%",
            //         },
            //         {
            //             Browser: "Outros",
            //             Porcentagem: 9.89,
            //             DataLabelMappingName: "Outros: 9.89%",
            //         },
            //     ]
            //     : [
            //         {
            //             Browser: "Produzindo",
            //             Porcentagem: 59.28,
            //             DataLabelMappingName: "Produzindo: 59.28%",
            //         },
            //         {
            //             Browser: "Manutenção",
            //             Porcentagem: 2.48,
            //             DataLabelMappingName: Browser.isDevice
            //                 ? "Manutenção <br> 2,48%"
            //                 : "Manutenção: 2.48%",
            //         },
            //         {
            //             Browser: "Falta de Material",
            //             Porcentagem: 5.55,
            //             DataLabelMappingName: "Falta de Material: 5.55%",
            //         },
            //         {
            //             Browser: "Não Informado",
            //             Porcentagem: 22.8,
            //             DataLabelMappingName: "Não Informado: 22.80%",
            //         },
            //         {
            //             Browser: "Outros",
            //             Porcentagem: 9.89,
            //             DataLabelMappingName: "Outros: 9.89%",
            //         },
            //     ],

            tooltip: {
                enable: true,
                format: "<b>${point.x}</b><br>Representa: <b>${point.y}%</b>",
                header: "",
            },

            dataLabel: {
                name: "text",
                visible: true,
                font: {
                    fontWeight: "600",
                    color: "#ffffff",
                    size: "15px",
                },
            },

            enableSmartLabels: true,
            enableAnimation: true,
            startAngle: Browser.isDevice ? "55" : "35",
            explodeOffset: "10%",
            explodeIndex: 0,
        };
    },
    mounted() {
        console.log("teste: ", this.dispData)
    },  
    provide: {
        accumulationchart: [
            AccumulationLegend,
            PieSeries,
            AccumulationTooltip,
            AccumulationDataLabel,
        ],
    },
    methods: {
      
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },
};
</script>
