import { createRouter, createWebHistory } from "vue-router"
import Home from "../app/pages/Home/index.vue"
import meusDashboards from "../app/pages/meusDashboards/index.vue";
import desempenho from "../app/pages/Dashboards/Desempenho.vue";
import paretoParadas from "../app/pages/Dashboards/ParetoParadas.vue";
import maquinasStatus from "../app/pages/Dashboards/MaquinasStatus.vue";
import dispResumida from "../app/pages/Dashboards/DispResumida.vue";
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
);
const rotaPadrao = 'app/apontamento-iot'
const rotaDashs = 'app/apontamento-iot/dash'

const routes = [
  { path: `/${rotaPadrao}/`, component: Home },
  { path: `/${rotaPadrao}/dashboards`, component: meusDashboards },
  { path: `/${rotaDashs}/desempenho/`, component: desempenho },
  { path: `/${rotaDashs}/paretoParadas/`, component: paretoParadas },
  { path: `/${rotaDashs}/maquinasStatus/`, component: maquinasStatus },
  { path: `/${rotaDashs}/dispResumida/`, component: dispResumida },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;