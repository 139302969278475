<template>
    <v-container fluid>
        <v-card height="100vh">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" @onGetGraficInformation="onGetGraficInformation" />
            <Pareto_motivo_de_parada/>
        </v-card>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import Pareto_motivo_de_parada from "./pareto_motivo_de_parada.vue";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-vue-calendars/styles/material.css';

import toolbarCharts from '../charts/toolbar/index.vue'

import { useRouter, useRoute } from "vue-router";

export default {
    name: "motivo_de_parada",
    components: {
        Pareto_motivo_de_parada,
        toolbarCharts
    },

    data: () => ({
        shifts : null,
        Router : useRouter(),
        current_path : '/app/apontamento-iot/dash/paretoParadas/'
    }),

    computed: {
        appCharts() {
            return useAppCharts();
        }
    },

    methods: {
        toolbarFiltros(event,values) {
            switch(event) {
                case 'shift':
                    this.shifts = values

                    console.log("turno atualizado",this.shifts)
                    break;
                case 'teste':
                    // code block
                    break;
                }
        },
        onGetGraficInformation(event) {
            if (this.Router.currentRoute.path == this.current_path) {
                const _shifts = this.shifts
                console.log('shifts',_shifts)
                useAppCharts().relatorio_de_motivos_de_parada(_shifts)
            }
        }
    }
}
</script>