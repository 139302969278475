<template>
    <v-card>
        <v-toolbar height="100%" class="py-1">
            <v-row justify="center">
                <v-col cols="12" sm="12" md="2" class="pl-4" style="align-content: center;">
                    <v-menu transition="scale-transition">
                        <template v-slot:activator="{ props }">
                            <v-btn color="white" v-bind="props" style="background-color: #121935;">
                                <small style="text-wrap: wrap;" class="font-weight-bold">Recarregar em: {{ timeSelect }}</small>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer;">
                                <v-btn width="100%" @click="onclickTime(item.title)">{{ item.title }}</v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col v-if="filtroGrupos" cols="12" :md="!filtroMaquinas || !filtroTurnos || !filtroData ? '3' : '2'" sm="6" class="align-center">
                    <v-select style="color: black !important; font-weight: bold;" density="compact" hide-details="false"
                        clearable bg-color="white" v-model="selectedGroup" :items="groupData" label="Grupos"
                        multiple>
                        <template v-slot:prepend-item>
                            <v-list-item title="Todos" @click="selectAllGroup">
                                <template v-slot:prepend>
                                    <v-checkbox-btn :model-value="allGroup"></v-checkbox-btn>
                                </template>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <div v-if="index < 1">
                                <span class="text-truncate">{{ item.title }}</span>
                            </div>
                            <span v-if="index === 1" class="font-weight-bold text-caption align-self-center">
                                (+{{ selectedGroup.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="filtroMaquinas" cols="12" :md="!filtroTurnos || !filtroGrupos || !filtroData ? '3' : '2'" sm="6" class="align-center">
                    <v-select style="color: black !important; font-weight: bold;" class="text-no-wrap" density="compact" hide-details="false"
                        clearable bg-color="white" v-model="selectedMachine" :items="machineData" item-title="MachineName" item-value="MachineId"
                        label="Máquinas" multiple>
                        <template v-slot:prepend-item>
                            <v-list-item title="Todos" @click="selectAllMachine">
                                <template v-slot:prepend>
                                    <v-checkbox-btn :model-value="allMachine"></v-checkbox-btn>
                                </template>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <div v-if="index < 1">
                                <span class="text-truncate">{{ item.title }}</span>
                            </div>
                            <span v-if="index === 1" class="font-weight-bold text-caption align-self-center">
                                (+{{ selectedMachine.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="filtroTurnos" cols="12" :md="!filtroMaquinas || !filtroGrupos || !filtroData ? '3' : '2'" sm="6" class="align-center">
                    <v-select style="color: black !important; font-weight: bold; text-wrap: nowrap !important;" density="compact" hide-details="false"
                        clearable bg-color="white" v-model="selectedShift" :items="shiftData" label="Turnos"
                        multiple>
                        <template v-slot:prepend-item>
                            <v-list-item title="Todos" @click="selectAllShift">
                                <template v-slot:prepend>
                                    <v-checkbox-btn :model-value="allShift"></v-checkbox-btn>
                                </template>
                            </v-list-item>


                        </template>
                        <template v-slot:selection="{ item, index }">
                            <div v-if="index < 1">
                                <span class="text-truncate">{{ item.title }}</span>
                            </div>
                            <span v-if="index === 1" class="font-weight-bold text-caption align-self-center">
                                (+{{ selectedShift.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="filtroData" cols="12" sm="6" md="3" lg="3" class="d-flex align-center">
                    <ejs-daterangepicker ref="DateRangePicker" placeholder="Selecionar Data" :format="'dd/MM/yyyy'" v-model="selectedDate"
                    @focus="showCalendar">
                        <e-presets>
                            <e-preset label="Esta Semana" :start='weekStartDate' :end='weekEndDate'></e-preset>
                            <e-preset label="Este Mês" :start='monthStartDate' :end='monthEndDate'></e-preset>
                            <e-preset label="Último Mês" :start='lastMonthStartDate' :end='lastMonthEndDate'></e-preset>
                            <e-preset label="Último Ano" :start='lastYearStartDate' :end='lastYearEndDate'></e-preset>
                        </e-presets>
                    </ejs-daterangepicker>
                </v-col>
                <v-col cols="12" sm="6" md="1" class="d-flex align-center px-0 pr-4">
                    <v-btn style="background-color: #04b834;" color="white"
                        @click='onGetGraficInformation'>Aplicar</v-btn>
                </v-col>
            </v-row>
        </v-toolbar>
    </v-card>
</template>

<script>
import { useAppCharts } from "../../../../stores/appCharts";
import { useAppMaquinas } from "../../../../stores/appMaquinas";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import { DateRangePickerComponent, PresetDirective, PresetsDirective } from '@syncfusion/ej2-vue-calendars';
import { formatDateBRToISO } from "../../../utils/funcoes";
import { formatedDateBR } from "../../../utils/funcoes";


export default {
    name: 'toolbarCharts',
    emits: ["toolbarFiltros"],
    props: {
        filtroMaquinas: {
            type:Boolean,
            default: true
        },
        filtroGrupos: {
            type:Boolean,
            default: false
        },
        filtroTurnos: {
            type:Boolean,
            default: true
        },
        filtroData: {
            type:Boolean,
            default: true
        }
    },
    components: {
        'ejs-multiselect': MultiSelectComponent,
        "ejs-daterangepicker": DateRangePickerComponent,
        'e-presets': PresetsDirective,
        'e-preset': PresetDirective
    },
    data() {
        let year = new Date().getFullYear();
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return {
            weekStartDate: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
            weekEndDate: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
                - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
            monthStartDate: new Date(new Date(new Date().setDate(1)).toDateString()),
            monthEndDate: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()),
            lastMonthStartDate: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
            lastMonthEndDate: new Date(new Date(new Date().setDate(0)).toDateString()),
            lastYearStartDate: new Date(year - 1, 1, 1).toDateString(),
            lastYearEndDate: new Date(year - 1, 11, 31).toDateString(),
            selectedDate: [],
            selectedGroup: [],
            selectedMachine: [],
            selectedShift: [],
            selectedStartDate: '',
            selectedEndDate: '',
            selectedMachinesURL: '',
            selectedGroupsURL: '',
            selectedShiftsURL: '',
            selectedDateURL: '',
            selectedTimeURL: '',
            hoje: today,
            groupData: ['Grupo1', 'Grupo2', 'Grupo3', 'Grupo4'],
            machineData: [],
            shiftData: [],
            resourceMaquinas: [],
            timeSelect: '',
            items: [
                { title: 'OFF' },
                { title: 'AUTO' },
                { title: '5S' },
                { title: '10S' },
                { title: '30S' },
                { title: '1M' },
                { title: '5M' },
                { title: '10M' },
                { title: '15M' },
                { title: '30M' },
                { title: '1H' },
                { title: '2H' },
                { title: '1D' },
            ],
        };
    },
    async created() {

        await this.getDataTurnos()
        await this.getArrayMachines()
        await this.getDates()
        this.getReload()

    },
    computed: {
        allGroup() {
            return this.selectedGroup.length === this.groupData.length
        },

        allShift() {
            return this.selectedShift.length === this.shiftData.length
        },

        allMachine() {
            return this.selectedMachine.length === this.machineData.length
        },
        appCharts() {
            return useAppCharts();
        },
        appMaquinas() {
            return useAppMaquinas();
        },

        urlShare() {
            let newUrl = "";

            if ((this.selectedGroup.length > 0) && (this.selectedMachine.length > 0)) {
                newUrl = `?${this.selectedMachinesURL}${this.selectedGroupsURL}${this.selectedShiftsURL}${this.selectedDateURL}${this.selectedTimeURL}`;
            };

            if ((this.selectedGroup.length == 0) && (this.selectedMachine.length > 0)) {
                newUrl = `?${this.selectedMachinesURL}${this.selectedShiftsURL}${this.selectedDateURL}${this.selectedTimeURL}`;
            };

            if ((this.selectedGroup.length > 0) && (this.selectedMachine.length == 0)) {
                newUrl = `?${this.selectedGroupsURL}${this.selectedShiftsURL}${this.selectedDateURL}${this.selectedTimeURL}`;
            };

            if ((this.selectedGroup.length == 0) && (this.selectedMachine.length == 0)) {
                newUrl = `?${this.selectedMachinesURL}${this.selectedShiftsURL}${this.selectedDateURL}${this.selectedTimeURL}`;
            };

            // if (window.location.href.includes('/performance/')) {
            //     let xMeta = 0;

            //     if (props.meta != null) {
            //         xMeta = props.meta;
            //     }

            //     newUrl += `&type=${props.type}&groupby=${props.groupby}&meta=${xMeta}`;
            // }

            window.history.replaceState(null, '', newUrl);
            return newUrl;
        }
    },
    watch: {
        selectedGroup(newVal) {
            this.$emit('toolbarFiltros', 'group', newVal)
        },
        selectedMachine(newVal) {
            if (this.selectedMachine.length === this.machineData.length) {
                this.selectedMachinesURL = ''
            } else {
                this.selectedMachinesURL = newVal.map(machine => '&machine=' + machine).join('')
            }

            this.$emit('toolbarFiltros', 'machine', newVal)
        },
        selectedShift(newVal) {
            if (this.shiftData.length === this.selectedShift.length) {
                this.selectedShiftsURL = ''
            } else {
                this.selectedShiftsURL = newVal.map(shift => '&shift=' + shift).join('');
            }

            this.$emit('toolbarFiltros', 'shift', newVal)
        },
        selectedDate(newVal) {
            if(newVal){
                if (newVal[0].toLocaleDateString() == this.hoje.toLocaleDateString() && newVal[1].toLocaleDateString() == this.hoje.toLocaleDateString()) { //convertido para string para realizar a comparação
                    this.selectedDateURL = ''
                } else {
                    this.selectedDateURL = `&date=${formatedDateBR(this.selectedDate[0])}-${formatedDateBR(this.selectedDate[1])}`
                }
            }
        },
        selectedStartDate(newVal) {
            this.$emit('toolbarFiltros', 'startDate', formatedDateBR(newVal))
        },

        selectedEndDate(newVal) {
            this.$emit('toolbarFiltros', 'endDate', formatedDateBR(newVal))
        },
        timeSelect(newVal) {
            if (newVal == '15M') {
                this.selectedTimeURL = ''
            } else {
                this.selectedTimeURL = `&reload=${newVal}`
            }
        }
    },
    methods: {
        onclickTime(time) {
            this.timeSelect = time;
        },
        async onGetGraficInformation() {

            if(!this.selectedDate) {
                this.selectedDate = [this.hoje, this.hoje]
            }

            if(this.selectedMachine.length == 0) {
                this.selectedMachine = this.machineData
            }

            if (this.selectedShift.length == 0){
                this.selectedShift = this.shiftData
            }

            this.urlShare

            this.$emit('onGetGraficInformation', 'onclick')
        },

        showCalendar() {
            this.$refs.DateRangePicker.show();  // Exibe o calendário
        },

        selectAllGroup() {
            if (this.allGroup) {
                this.selectedGroup = []
            } else {
                this.selectedGroup = this.groupData.slice()
            }
        },
        selectAllMachine() {
            if (this.allMachine) {
                this.selectedMachine = []
            } else {
                this.selectedMachine = this.machineData.slice()
            }
        },
        selectAllShift() {
            if (this.allShift) {
                this.selectedShift = []
            } else {
                this.selectedShift = this.shiftData.slice()
            }
        },
        async getDates() {
            if (window.location.href.includes('&date=')) {
                let urlParts = window.location.href.split('&date=');
                let datesURL = urlParts.slice(1).map(part => part.split('&')[0])
                let startAndEnd = datesURL[0].split('-')
                this.selectedDate = [formatDateBRToISO(startAndEnd[0]), formatDateBRToISO(startAndEnd[1])]
                this.selectedStartDate = this.selectedDate[0]
                this.selectedEndDate = this.selectedDate[1]
            } else {
                this.selectedDate = [this.hoje, this.hoje]
                this.selectedStartDate = this.selectedDate[0]
                this.selectedEndDate = this.selectedDate[1]
            };

        },
        async getDataTurnos() {
            this.appCharts.listar_turnos().then(() => {
                this.$nextTick(() => {

                    this.shiftData = this.appCharts.shiftData;
                    if (window.location.href.includes('&shift=')) {
                        let urlParts = window.location.href.split('&shift=');
                        this.selectedShift = urlParts.slice(1).map(part => {
                            let shift = part.split('&')[0].replace(/\+/g, ' ');  
                            return decodeURIComponent(shift); 
                        }).filter(shift => shift.trim() !== '');  
                    } else {
                        this.selectedShift = this.shiftData; 
                    }
                });
            }).catch(error => {
                console.error("Erro ao carregar os turnos", error);
            })
        },

        async getArrayMachines() {
            this.appMaquinas.listar_maquinas().then(() => {
                this.$nextTick(() => {

                    this.machineData = this.appMaquinas.resourceMaquinas.map(
                        maquina => ({
                           MachineName: maquina.nome,
                           MachineId: maquina.id
                        }));

                    if (window.location.href.includes('&machine=')) {
                        let urlParts = window.location.href.split('&machine=');
                        this.selectedMachine = urlParts.slice(1).map(part => part.split('&')[0]);
                    } else {
                        this.selectedMachine = this.machineData
                    };
                });
            }).catch(error => {
                console.error("Erro ao máquinas", error)
            })
        },

        getReload() {
            if (window.location.href.includes('&reload=')) {
                let urlParts = window.location.href.split('&reload=');
                this.timeSelect = urlParts[1]
            } else {
                this.timeSelect = '15M'
            }
        }
    },
};
</script>

<style scoped></style>