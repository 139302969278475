<template>
    <div class="infoprod pt-0 pb-0">
        <v-row class="px-2 pl-sm-0  pl-md-0 pl-lg-0 pl-xl-0 pl-xxl-0">
            <v-col cols="12" class="pa-0 ">
                <v-card-text
                    class="text-lg-body-1 text-xl-h6 text-center font-weight-bold pt-0 pb-2 pb-sm-1 pt-sm-1 pt-lg-0 pb-1 pt-md-0">INFORMAÇÕES
                    DA PRODUÇÃO:</v-card-text>
            </v-col>
            <v-col cols="6" class="px-2 py-lg-2 pt-lg-0 pt-md-4 pt-sm-2">
                <div style="display: flex; align-items: center;">
                    <v-text-field hide-details="false" :density="inputText.size" :variant="inputTextVariant.variant"
                        class="mb-0 pb-0" label="OPERADOR" v-model="appStore.apontamento_atual.funcionario.nome"
                        readonly style="font-weight: bold;width: 90%;" placeholder="Não Informado"
                        persistent-placeholder clearable @click:clear="ClearFuncionario"
                        @click="ShowFuncionarios"></v-text-field>

                    <v-btn :size="squareButtons.size" @click="ShowFuncionarios" color="secondary"
                        data-cy="operador_tempo_real"> <v-icon size="x-large" icon="mdi-magnify"></v-icon></v-btn>
                </div>
            </v-col>

            <v-col cols="6" class="px-2 py-lg-2 pt-lg-0 pt-md-4 pt-sm-2">
                <div style="display: flex; align-items: center;">
                    <v-text-field :density="inputText.size" hide-details="false" :variant="inputTextVariant.variant"
                        label="OBSERVAÇÃO" v-model="appStore.apontamento_atual.observacao" readonly
                        style="font-weight: bold;width: 90%;" id="input_obs" placeholder="Não Informado"
                        persistent-placeholder clearable @click:clear="ClearObs" @click="ShowObs"></v-text-field>

                    <v-btn :size="squareButtons.size" data-cy="obs_tempo_real" color="secondary"
                        @click="ShowObs"><v-icon size="x-large" icon="mdi-file-edit-outline"></v-icon> </v-btn>
                </div>
            </v-col>

            <v-col v-if="(appStore.apontamento_atual.tipo == 'Parada')" cols="12" sm="12" md="6" lg="6" xl="6"
                class="px-2 py-lg-1 py-sm-2 py-md-3 py-lg-2">
                <div style="display: flex; align-items: center;" v-if="(!awesome)">
                    <v-text-field :density="inputText.size" hide-details="false" :variant="inputTextVariant.variant"
                        :label="(appStore.apontamento_atual.tipo == 'Parada') && (appStore.apontamento_atual.desc_motivo_parada == null) ? 'INFORMAR O MOTIVO DE PARADA' : 'MOTIVO PARADA'"
                        readonly v-model="appStore.apontamento_atual.desc_motivo_parada"
                        style="font-weight: bold;width: 90%;" placeholder="Não Informado" persistent-placeholder
                        clearable @click:clear="ClearMotivoParada" @click="ShowMp"></v-text-field>

                    <v-btn v-if="appStore.apontamento_atual.motivo_de_parada_manutencao_setup !== 'Setup'"
                        :size="squareButtons.size" @click="ShowMp" data-cy="motivo_de_parada_tempo_real"
                        color="secondary"> <v-icon size="x-large" icon="mdi-magnify"></v-icon></v-btn>
                </div>
            </v-col>

            <v-col cols="12" class="px-2  py-lg-1 py-sm-2 py-md-3 py-lg-2" sm="12"
                :md="appStore.apontamento_atual.tipo != 'Parada' ? '12' : '6'">
                <div style="display: flex; align-items: center;">
                    <v-text-field :density="inputText.size" hide-details="false" :variant="inputTextVariant.variant"
                        label="ORDEM DE PRODUÇÃO" readonly v-model="appStore.apontamento_atual.op"
                        style="font-weight: bold;width: 90%;" id="teste2" placeholder="Não Informado"
                        persistent-placeholder clearable @click:clear="ClearOP" @click="ShowOP"></v-text-field>

                    <v-btn :size="squareButtons.size" @click="ShowOP" color="secondary"
                        data-cy="ordem_de_producao_tempo_real"> <v-icon size="x-large"
                            icon="mdi-magnify"></v-icon></v-btn>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card-text style="cursor: pointer; margin-top: -20px; font-weight: bold; "
                    class="py-0 pl-0 mb-1 brokenText">
                    {{ appStore.apontamento_atual.op }}
                </v-card-text>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { useAppStore } from '../../stores/app';
import { useAppApontamento } from '../../stores/appApontamento';
import { useAppFuncionarios } from '../../stores/appFuncionarios';
import { useAppOrdemProducao } from '../../stores/appOrdemProducao';
import { useAppMotivoDeParada } from '../../stores/appMotivosdeParada';

import { useDisplay } from 'vuetify'

import { useResponsiveSizes } from '../utils/style'


export default {
    name: "infoprodApontamento",
    data() {
        return {
            isMenuOpen: false,
            filter_funcionario: '',
            inputText: useResponsiveSizes().inputText,
            inputTextVariant: useResponsiveSizes().inputTextVariant,
            squareButtons: useResponsiveSizes().squareButtons
        }
    },
    methods: {
        async ShowFuncionarios() {
            useAppStore().load_actions = { message: "CARREGANDO FUNCIONARIOS", showMessage: true };
            await useAppFuncionarios().listar_funcionarios();
            useAppFuncionarios().showModal = true;
            useAppStore().load_actions.showMessage = false;
            console.log(useAppApontamento)
        },

        SelecionarFuncionario(funcionario) {
            const json_update = { operador: funcionario.name }
            useAppStore().selecionar_funcionario(funcionario);
            console.log(selecionar_funcionario)
            useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, json_update)
            this.isMenuOpen = false;
        },

        async ShowOP() {
            await useAppOrdemProducao().listar_ops(0)
            useAppStore().view_apontamento_historico = false;
            useAppOrdemProducao().showModal = true;
        },

        ShowObs() {
            useAppStore().view_apontamento_historico = false;
            useAppApontamento().observacao_modal = useAppStore().apontamento_atual.observacao;
            useAppApontamento().showModalObs = true;
        },

        async ClearOP() {
            useAppStore().load_actions = { message: "SALVANDO APONTAMENTO", showMessage: true };
            useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, { ordem_de_producao: null })
            this.appStore.apontamento_atual.op = null
            useAppStore().load_actions.showMessage = false;
        },

        async ClearFuncionario() {
            useAppStore().load_actions = { message: "SALVANDO APONTAMENTO", showMessage: true };

            await useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, { operador: null })

            this.appStore.apontamento_atual.funcionario.nome = ""

            useAppStore().load_actions.showMessage = false;
        },

        async ClearObs() {
            useAppStore().load_actions = { message: "SALVANDO APONTAMENTO", showMessage: true };
            await useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, { observacao: null })
            this.appStore.apontamento_atual.observacao = null
            useAppStore().load_actions.showMessage = false;
        },

        async ShowMp() {
            useAppMotivoDeParada().apontamento_name = this.appStore.apontamento_atual.name;

            await useAppMotivoDeParada().listar_motivos_de_parada();

            this.appStore.showModalMotivoDeParada = true;
        },

        async ClearMotivoParada() {
            try {
                useAppStore().load_actions = { message: "SALVANDO APONTAMENTO", showMessage: true };

                const json_update = {
                    planejada: 0,
                    setup: 0,
                    manutencao: 0,
                    motivo_de_parada: null,
                    desc_motivo_parada: null
                };

                await useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update);

                this.appStore.apontamento_atual.desc_motivo_parada = null;
                useAppStore().load_actions.showMessage = false;

            } catch (error) {
                console.error('Erro ao limpar motivo de parada:', error);
                this.appStore.message = { message: 'Erro ao limpar motivo de parada.', color: 'red', showMessage: true };
            }
        },

        HideMotivoParada() { this.isMotivoParadaVisible = false; }
    },

    computed: {
        appStore() { return useAppStore(); },
        appApontamento() { return useAppApontamento(); },
        appOrdemProducao() { return useAppOrdemProducao(); },
        motivoDeParadaSetup() {
            return useAppApontamento().resourceApontamento
                ? useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup
                : null;
        }
    },
}
</script>

<style scoped>
.brokenText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>