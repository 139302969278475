import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app apontamento" }
const _hoisted_2 = {
  class: "ml-1",
  style: {"font-size":"14px"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_statusMachine = _resolveComponent("statusMachine")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_detalheApontamento = _resolveComponent("detalheApontamento")
  const _component_funcionarios = _resolveComponent("funcionarios")
  const _component_ordemProducao = _resolveComponent("ordemProducao")
  const _component_apontamento = _resolveComponent("apontamento")
  const _component_observacao = _resolveComponent("observacao")
  const _component_historico = _resolveComponent("historico")
  const _component_detalhes_os = _resolveComponent("detalhes_os")
  const _component_maquinas = _resolveComponent("maquinas")
  const _component_motivo_parada = _resolveComponent("motivo_parada")
  const _component_load = _resolveComponent("load")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_row = _resolveComponent("v-row")
  const _component_VSnackbar = _resolveComponent("VSnackbar")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_statusMachine),
        _createVNode(_component_v_divider, { class: "my-0 pb-1" }),
        _createVNode(_component_detalheApontamento)
      ]),
      _createVNode(_component_funcionarios),
      _createVNode(_component_ordemProducao),
      _createVNode(_component_apontamento),
      _createVNode(_component_observacao),
      _createVNode(_component_historico),
      _createVNode(_component_detalhes_os),
      _createVNode(_component_maquinas),
      _createVNode(_component_motivo_parada),
      _createVNode(_component_load),
      _createVNode(_component_VSnackbar, {
        timeout: 2000,
        style: {"font-weight":"bold"},
        modelValue: $options.appStore.message.showMessage,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.appStore.message.showMessage) = $event)),
        color: $options.appStore.message.color,
        location: "top end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            justify: "space-between",
            align: "center",
            class: "d-flex align-center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("small", _hoisted_2, _toDisplayString($options.appStore.message.message), 1 /* TEXT */),
              _createVNode(_component_v_icon, {
                size: "60",
                color: "#FFEA00"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-alert-box")
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "color"])
    ]),
    _: 1 /* STABLE */
  }))
}