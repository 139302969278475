<template>
    <view_charts />
</template>

<script>
import view_charts from '../../producao/charts/view.vue'

export default {
    name: "meusDashboards",
    components: {
        view_charts
    }
}
</script>

<style scoped></style>