<template>
    <charts_recursos_por_status />
</template>

<script>
import charts_recursos_por_status from '../../producao/charts/recursos_por_status.vue';
export default {
    name: "maquinasStatus",
    components: {
        charts_recursos_por_status
    }
}
</script>

<style scoped></style>